import React, {useState} from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Button from "../elements/Button";
import copy from 'copy-to-clipboard';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);
  const [donationModalActive, setDonationModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const openDonationsModal = (e) => {
    e.preventDefault();
    setDonationModalActive(true);
  }

  const closeDonationsModal = (e) => {
    e.preventDefault();
    setDonationModalActive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const renderDonations = () => {
    return (
        <div>
          <h5>БО 'БЛАГОДІЙНИЙ ФОНД '2040 ДОПОМОГА'</h5>
          <h6>
            <i>Реквізити банку:</i>
          </h6>
          <h6>
            <Button color="secondary" size="sm"
                    onClick={() => copy('UA293052990000026005020405014')}>
              <span role="img" aria-label="copy">📋<i> UAH IBAN:</i></span>
            </Button>
            <p>UA293052990000026005020405014</p>
          </h6>
          <h6>
            <Button color="secondary" size="sm"
                    onClick={() => copy('UA253052990000026002030403269')}>
              <span role="img" aria-label="copy">📋<i> USD IBAN:</i></span>
            </Button>
            <p>UA253052990000026002030403269</p>
          </h6>
          <h6>
            <Button color="secondary" size="sm"
                    onClick={() => copy('UA163052990000026009020410447')}>
              <span role="img" aria-label="copy">📋<i> EUR IBAN:</i></span>
            </Button>
            <p>UA163052990000026009020410447</p>
          </h6>
          <h6>
            <Button color="secondary" size="sm"
                    onClick={() =>  copy('44662342')}>
              <span role="img" aria-label="copy">📋<i> ЄДРПОУ:</i></span>
            </Button>
            <p>44662342</p>
          </h6>
          <h6>
            <Button color="secondary" size="sm"
                    onClick={() =>  copy('\'Добровільний внесок від (ім\'я чи назва вашої організації)\'')}>
              <span role="img" aria-label="copy">📋<i> Коментар:</i></span>
            </Button>
            <p>'Добровільний внесок від (ім'я чи назва вашої організації)'</p>
          </h6>
          <h6>
            <i>Paypal: iimetra@yahoo.com</i>
          </h6>
          <h6>
            <i>Поповнення карткового рахунку:</i>
          </h6>
          <h6>
            <Button color="secondary" size="sm"
                    onClick={() =>  copy('4246001003415035')}>
              <span role="img" aria-label="copy">📋<i>Приватбанк:</i></span>
            </Button>
            <p>4246001003415035</p>
          </h6>
          <h6>
            <Button color="secondary" size="sm"
                    onClick={() =>  copy('https://send.monobank.ua/74hLVj6zL5')}>
              <span role="img" aria-label="copy">📋<i>Монобанк:</i></span>
            </Button>
            <p><a href={"https://send.monobank.ua/74hLVj6zL5"}>Клік</a></p>
          </h6>
        </div>
    );
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <Button color="primary" onClick={openDonationsModal}>
          <span className="text-color-secondary">Збір коштів</span>
        </Button>
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Благодійний фонд <br/><span className="text-color-primary">2040</span> <span className="text-color-secondary">Допомога</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Підтримуємо Харків'ян які залишилися у біді.
                </p>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/699003278"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.png')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div>
          <Modal
              id="donation-modal"
              show={donationModalActive}
              handleClose={closeDonationsModal}
              children={renderDonations()} />
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/699003278?autoplay=1"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
