import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to={{pathname: "https://youcontrol.com.ua/catalog/company_details/44662342/"}} target="_blank">Реєстраційні дані</Link>
        </li>
        <li>
          <Link to="#"
                onClick={() => window.location = 'mailto:2040dopomoga@gmail.com'}>Контакти</Link>
        </li>
        <li>
          <Link to={{pathname: "https://www.facebook.com/2040dopomoga"}} target="_blank">Про нас</Link>
        </li>
        <li>
          <Link to={{pathname: "https://www.facebook.com/2040dopomoga"}} target="_blank">Запитання та відповіді</Link>
        </li>
        <li>
          <Link to={{pathname: "https://www.facebook.com/2040dopomoga"}} target="_blank">Підтримка</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;
